@use 'src/themes/variables' as *;

.Sidebar {
  z-index: 999;
  overflow: auto;
  overflow-x: hidden;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 16px;
  border-right: 1px solid $bg-input-color;

  &.ant-layout-sider-collapsed {
    .Sidebar__menu {
      text-align: center;

      .SidebarItem {
        justify-content: center;
        width: 48px;
        margin: auto;

        &.active {
          border-radius: 50%;
        }
      }

      .SidebarItem__label {
        display: none;
      }
    }
  }

  &__logo {
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }

  &__menu {
    margin-top: 16px;

    > a {
      display: block;
    }
  }

  &.ant-layout-sider {
    position: fixed;
  }
  a {
    color: $font-color-default;
  }

  .SidebarItem {
    display: flex;
    height: 48px;
    padding: 0 16px;
    align-items: center;
    border-radius: 10px;

    .active-icon {
      display: none;
    }

    &.active {
      background-color: $bg-gray;

      .active-icon {
        display: inline-block;
      }

      .icon {
        display: none;
      }

      .SidebarItem__label {
        color: $color-primary;
      }
    }

    &__label {
      margin-left: 16px;
    }
  }
}
