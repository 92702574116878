@use 'src/themes/variables' as *;

.ForgotPasswordPage {
  &__heading {
    margin-bottom: 16px;
  }

  &__more-info-text {
    color: $font-color-caption;
    margin-bottom: 16px;
  }

  &__heading,
  &__endText {
    text-align: center !important;
    font-size: 12px;
  }

  &__endText {
    margin-top: 16px;
  }

  &__email {
    &.sent {
      display: none;
    }
  }

  &__errorMsg {
    margin-bottom: 16px;
  }
}
