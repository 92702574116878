@use 'src/themes/variables' as *;

.FilesPage {
  &__wrapper {
    .CommonContent__content {
      padding: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 16px;
    width: 1020px;
    height: 742px;
    border-radius: 0px 0px 8px 8px;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;

    &-left {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-action {
      color: $font-color-caption;

      &-btn {
        &.ant-btn {
          color: $color-primary;
          width: 36px;
          height: 36px;

          &.noBorder {
            box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
