@use 'src/themes/variables' as *;

.pricing-loading {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

.pricing-cards {
  display: flex;
  gap: 16px;
  margin-top: 46px;

  .card {
    width: 100%;
    max-width: 370px;
    border-radius: 10px;

    &.active {
      border-top: 5px solid $color-success;
      box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
    }

    .ant-card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .package-name {
    color: $color-success;
  }

  .package-price {
    display: flex;
    gap: 8px;

    .currency {
      margin: 7px 0 -7px;
    }
  }

  .license {
    font-family: 'Poppins-500';
  }

  .divider {
    margin: 10px 0;
  }

  .package-description {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 14px;
  }

  .learn-more {
    align-items: center;
    color: $color-primary;
    gap: 6px;
  }

  .info-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  .info-icon {
    background-color: $grey;
  }

  .plan-container {
    margin-bottom: 10px;
  }

  .plan-select-text {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .button {
    width: 100%;

    &.active {
      color: white;
      background-color: $color-primary;
    }

    &.disable {
      background-color: $color-offline;
      color: white;
    }
  }
}

.PricingPackage__modal {
  .ant-modal-content {
    padding: 20px;

    .PricingPackage__trail-text {
      text-align: center;

      .body1 {
        color: $font-color-caption;
      }
    }
  }
}
