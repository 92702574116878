@use 'src/themes/variables' as *;

.Dashboard {
  display: flex;
  flex-direction: column;
  padding: 30px 40px 70px;
  gap: 16px;
  width: 100%;
  max-width: 1100px;

  .WelcomeCard {
    display: flex;
    flex: 1;
    background: #ffffff;
    border-radius: 8px;

    &__left {
      flex: 1;
      padding: 20px;

      &--quote {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px 30px;
        gap: 20px;
        height: 244px;

        &-author {
          color: #8a8a8a;
        }
      }
    }

    &__right {
      flex: 1;
      position: relative;
      background-color: #f0b8c7;
      border-radius: 0px 0px 0px 160px;
      overflow: hidden;
      img {
        position: absolute;
        height: 100%;
        bottom: 0;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .StatisticCards {
    display: flex;
    gap: 16px;

    .MessagesCard {
      padding-bottom: 30px;
      overflow: auto;

      &__link {
        font-size: 12px;
        position: absolute;
        top: -25px;
        right: 0;
      }

      &__conversationBox {
        position: relative;
        gap: 16px;
        height: 296px;
        overflow: auto;
        margin-top: 16px;
      }

      &__footer {
        margin-top: 16px;
        color: $font-color-caption;
      }
    }
  }
}
