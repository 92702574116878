@use 'src/themes/variables' as *;

.Notifications {
  cursor: pointer;

  &.ant-badge {
    .ant-badge-count {
      &.ant-scroll-number {
        height: 16px;
        line-height: 16px;
        min-width: 16px;
        font-size: 10px;
      }
    }
  }
  &__Popover {
    &.ant-popover {
      .ant-popover-inner {
        border-radius: 8px;
        padding: 16px 8px;
        box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
