@use 'src/themes/variables' as *;

.HomeworkList {
  height: calc(100% - 175px);
  overflow: auto;

  &.has-border {
    padding: 6px;
    border: 1px solid $border-default-color;
    border-radius: 8px;
  }
}
