.StatisticCard {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  height: 386px;
  background: white;

  &__title {
    z-index: 2;
    padding-left: 10px;
    position: relative;
    .decor {
      position: absolute;
      width: 4px;
      height: 100%;
      border-radius: 10px;
      left: 0;
    }
  }

  img {
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
  }

  &__content {
    position: relative;
    z-index: 2;
  }
}
