@use 'src/themes/variables' as *;

.HomeworkHistoryDetailsModal {
  &.ant-modal {
    .ant-modal {
      &-content {
        padding: 20px 20px 30px;
      }
    }
  }

  &__title {
    margin-bottom: 16px;
  }

  &__action {
    width: 170px;
    margin: 16px auto 0;
  }

  &__video {
    display: flex;
    gap: 16px;
    background-color: transparent;
    padding: 0;
    margin: 8px 0 16px;

    &-info {
      flex: 1;
      background: $bg-gray;
      border-radius: 8px;
      padding: 16px 16px 30px;
      text-align: left;

      &-description {
        margin: 8px 0 10px 0;
      }

      &-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;

        svg {
          margin-right: 6px;
        }
      }

      &-hour {
        margin-left: 6px;
      }
    }
  }

  &__comment {
    margin-top: 16px;
  }

  &__result {
    margin-top: 16px;
  }

  &__info {
    background: $bg-gray;
    border-radius: 8px;
    padding: 16px 16px 30px;
    margin-bottom: 16px;

    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
    }
  }
}
