.InviteModal {
  .ant-modal-content {
    padding: 30px 20px;

    .invite-modal {
      width: 100%;
      text-align: center;

      &__button {
        width: 200px;
        height: 48px;
        align-items: center;
        display: flex;
        margin: auto;
        padding: 0 24px;
      }

      .left-input {
        width: 260px;
        margin-right: 10px;
      }

      .right-input {
        width: 260px;
      }
    }
  }
}
