@use 'src/themes/variables' as *;

.ResetPasswordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 40px;

  &__container {
    max-width: 436px;

    &-heading {
      margin-bottom: 16px;
    }

    &-more-info-text {
      color: $font-color-caption;
      margin-bottom: 16px;
    }

    &-endText {
      text-align: center !important;
      font-size: 12px;
    }
  }

  &__errorMsg {
    margin-top: 16px;
  }

  .ErrorScreen {
    margin-top: 0;
  }
}
